
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    // global-css-imports start
//  note: if you are modifying the CSS on these blocks, please also modify it on .storybook/preview.tsx
// CSS library import
import '@speechifyinc/voice-cloning-web/dist/index.css';
import 'nprogress/nprogress.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'simplebar-react/dist/simplebar.min.css';
// local CSS import
import 'components/sidebar/controls/AskAi/AskAi.css';
import 'css/animations.css';
import 'css/experience.css';
import 'css/fonts.css';
import 'css/main.css';
import 'css/normalize.css';
import 'css/sharing.css';
import 'css/tailwind.css';
import 'css/three-dots.min.css';
// global-css-imports end
import React, { useEffect } from 'react';
import { OcrUpsell } from 'components/library/import/OcrUpsell';
import { loadFeatureFlags } from 'hooks/useFeatureFlags';
import withFaro from 'lib/observability/withFaro';
import * as speechify from 'lib/speechify';
import { setAudioServerUser } from 'lib/speechify/adaptors/audioServer';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';
import { actions as appActions } from 'store/app';
import { actions as usageActions } from 'store/usage';
import { initAnalytics } from 'utils/analytics';
import { initMessageListener } from 'utils/extension';
const MainWrapper = dynamic(() => import('wrappers/MainWrapper'), {});
const SmartBanner = dynamic(() => import('components/elements/SmartBanner'), {});
const CheckoutModal = dynamic(() => import('modules/checkout/components/CheckoutModal'), {});
const App = ({ Component, pageProps }: AppProps): JSX.Element => {
    const router = useRouter();
    const [userId, setUserId] = React.useState<string | null>(null);
    useEffect(() => {
        store.dispatch(appActions.setLocale(router.locale));
        initAnalytics();
        const removeMessageListener = initMessageListener();
        speechify.auth.onAuthStateChanged(async (user) => {
            if (user?.uid) {
                loadFeatureFlags(user.uid);
                store.dispatch(usageActions.fetchUserUsageData());
                setUserId(user.uid);
                setAudioServerUser(user);
            }
        });
        return () => {
            removeMessageListener();
        };
        // ESLint: React Hook useEffect has a missing dependency: 'router.locale'. Either include it or remove the dependency array.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MainWrapper>
          <SmartBanner />
          <CheckoutModal />
          <Component {...pageProps}/>
          {userId && <OcrUpsell />}
        </MainWrapper>
      </PersistGate>
    </Provider>);
};
const __Next_Translate__Page__1932b0168c0__ = withFaro(App);

    export default __appWithI18n(__Next_Translate__Page__1932b0168c0__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  